<template>
  <v-row>
<!--    <v-col cols="12">-->
<!--      <v-textarea v-if="$vuetify.breakpoint.mobile"-->
<!--                  dense-->
<!--                  rows="3"-->
<!--                  label="* Descrição"-->
<!--                  :rules="[-->
<!--                      this.$validators.string.required-->
<!--                  ]"-->
<!--                  v-model.trim="value.descricao"/>-->
<!--      <v-text-field v-else label="* Descrição"-->
<!--                    dense-->
<!--                    :rules="[-->
<!--                      this.$validators.string.required-->
<!--                  ]"-->
<!--                    v-model.trim="value.descricao"/>-->
<!--    </v-col>-->
    <v-col cols="12" md="6">
      <search-box
          label="* Tipo Turma"
          popup-label="Selecione um curso"
          :id.sync="value.idTipoTurma"
          :descricao.sync="value.descricao_idTipoTurma"
          :value="tipoTurma"
          :loader-fn="loaderTipo"
          item-key="id"
          item-text="descricao"
          :rules="[
              this.$validators.notNullOrUndefined
          ]">
        <template v-slot:empty>
          <p class="ma-2">
            Nenhum tipo encontrado
          </p>
        </template>

      </search-box>
    </v-col>
    <v-col cols="12" md="6">
      <search-box
          label="* Quadra"
          popup-label="Selecione uma quadra"
          :id.sync="value.idQuadra"
          :descricao.sync="value.descricao_idQuadra"
          :value="quadra"
          :loader-fn="loaderQuadra"
          item-key="id"
          item-text="descricao"
          :rules="[
              this.$validators.notNullOrUndefined
          ]">
        <template v-slot:empty>
          <p class="ma-2">
            Nenhuma quadra encontrada
          </p>
        </template>

      </search-box>
    </v-col>
    <v-col cols="12">
      <search-box
          label="* Professor"
          popup-label="Selecione um Professor"
          :id.sync="value.idProfessor"
          :nome.sync="value.nome_idProfessor"
          :value="professor"
          :loader-fn="loaderProfessor"
          item-key="id"
          item-text="nome"
          :rules="[
              this.$validators.notNullOrUndefined
          ]">
        <template v-slot:empty>
          <p class="ma-2">
            Nenhum Professor encontrado
          </p>
        </template>

      </search-box>
    </v-col>
<!--    <v-col cols="12" md="6">-->
<!--      <search-box-->
<!--          label="* Aula"-->
<!--          popup-label="Selecione uma aula"-->
<!--          :id.sync="value.idAula"-->
<!--          :descricao.sync="value.descricao_idAula"-->
<!--          :value="aula"-->
<!--          :loader-fn="loaderAula"-->
<!--          item-key="id"-->
<!--          item-text="descricao"-->
<!--          :rules="[-->
<!--              this.$validators.notNullOrUndefined-->
<!--          ]">-->
<!--        <template v-slot:empty>-->
<!--          <p class="ma-2">-->
<!--            Nenhuma aula encontrada-->
<!--          </p>-->
<!--        </template>-->

<!--      </search-box>-->
<!--    </v-col>-->
    <v-col cols="12" md="6">
      <search-box
          label="* Horario"
          popup-label="Selecione um horario"
          :id.sync="value.idHorario"
          @input="updateRange"
          :value="horario"
          :loader-fn="loaderHorario"
          item-key="id"
          item-text="range"
          :rules="[
              this.$validators.notNullOrUndefined
          ]">
        <template v-slot:empty>
          <p class="ma-2">
            Nenhum horario encontrado
          </p>
        </template>

      </search-box>
    </v-col>
    <v-col cols="12" md="6" class="pt-7">
      <lookup-dominio label="* Dia da Semana"
                      :hidden-refresh="true"
                      :hidden-clear="true"
                      v-model="value.diaSemana"
                      :type="lookups.diaSemana"
                      :rules="[
                      this.$validators.string.required
                  ]"/>
    </v-col>
<!--    <v-col cols="12" md="6">-->
<!--      <v-select label="* Status"-->
<!--                item-value="id"-->
<!--                item-text="text"-->
<!--                :items="status" v-model="value.status" :rules="[-->
<!--              this.$validators.notNullOrUndefined-->
<!--      ]"></v-select>-->
<!--    </v-col>-->
  </v-row>
</template>

<script>
import SearchBox from "../common/SearchBox";
import {findAll} from "../../../api/tipoTurma";
import {findAll as findAllQuadras} from '../../../api/quadras';
import {findAll as findAllAulas} from '../../../api/aulas';
import {findAll as findAllProfessores} from '../../../api/professores';
import {findAll as findAllHorarios} from '../../../api/horarios';
import LookupDominio from "../common/LookupDominio";
import {ItemDeDominio} from "../../../api/itemsDominio";

export default {
  name: "TurmaForm",
  components: {LookupDominio, SearchBox},
  props: {
    value: {
      type: Object
    }
  },

  computed: {
    tipoTurma() {
      if(this.value.idTipoTurma == null) return null;
      return {
        id: this.value.idTipoTurma,
        descricao: this.value.descricao_idTipoTurma
      }
    },
    quadra() {
      if(this.value.idQuadra == null) return null;
      return {
        id: this.value.idQuadra,
        descricao: this.value.descricao_idQuadra
      }
    },
    aula() {
      if(this.value.idAula == null) return null;
      return {
        id: this.value.idAula,
        descricao: this.value.descricao_idAula
      }
    },

    professor() {
      if(this.value.idProfessor == null) return null;
      return {
        id: this.value.idProfessor,
        nome: this.value.nome_idProfessor
      }
    },
    horario() {
      if(this.value.idHorario == null) return null;
      return {
        id: this.value.idHorario,
        range: this.value.hora_inicio_idHorario
      }
    }
  },
  data() {
    return {
      lookups: {
        diaSemana: ItemDeDominio.ARR_DIAS_SEMANA
      },
      status: [
        {
          id: 'A',
          text: 'Ativo'
        },
        {
          id: 'I',
          text: 'Inativo'
        }
      ],
      loaderTipo(page, search) {
        return findAll(page, {
          descricao: search
        })
      },
      loaderQuadra(page, search) {
        return findAllQuadras(page, {
          descricao: search
        })
      },

      loaderAula(page, search) {
        return findAllAulas(page, {
          descricao: search
        })
      },

      loaderProfessor(page, search) {
        return findAllProfessores(page, {
          nome: search
        })
      },

      loaderHorario(page, search) {
        return findAllHorarios(page, {
          horaInicio: search
        })
      }
    }
  },
  methods: {
    updateRange(range) {
      this.value.hora_inicio_idHorario = range ? range.horaInicio : null;
    }
  }
}
</script>

<style scoped>

</style>