<template>
  <div v-if="values" class="quadro" :class="{'is-mobile': this.$vuetify.breakpoint.mobile}">
    <create-edit-matricula-dialog :item="item" v-model="editDialogMatriculaOpened"
														  @onSave="refresh"/>
    <create-edit-turma-dialog :item="itemQuadra" v-model="editDialogQuadroAulaOpened"
                              @onSave="refresh"/>
    <v-progress-linear indeterminate color="primary" v-if="loading"/>
    <section class="calendar">
      <div class="horario body-2">
        {{ horario }}
      </div>
      <div class="dias">
        <div class="day" v-for="value in intervalDays" :key="value.weekDay">
          <p class="ma-0">{{ value.day }}</p>
          <p class="ma-0">{{ value.date }}</p>
        </div>
      </div>
    </section>
    <section class="quadras" :class="{'is-mobile': this.$vuetify.breakpoint.mobile}">
      <div class="quadra" v-for="quadra in quadrasKeys" :key="quadra" :style="{
        width: quadraSize + '%'
      }">
        <div class="quadra-info">
          <p class="body-2 ma-0">{{ quadra }}</p>
        </div>
        <div class="aulas">
          <aula-section v-for="value in intervalDays" :key="quadra + value.weekDay"
                        :selected-key="hoverKey"
                        :quadra="quadra"
                        :week-day="value.weekDay"
                        :quadras="quadras"
                        @sendIdMatriculaBoard="abreEdicaoMatricula($event)"
                        @sendIdQuadroAulaBoard="abreEdicaoQuadroAula($event)"
                        @hoverTurma="markHover" @leaveTurma="removeKey"/>
        </div>
      </div>
    </section>
  </div>
  <div v-else class="d-flex justify-center align-center flex-grow-1 flex-column" style="height: 100%">
    <p>Selecione os filtros para visualizar o Quadro de Disponibilidade</p>
    <v-icon>mdi-calendar-blank-outline</v-icon>
  </div>
</template>

<script>
import {findDisponibilidade} from "../../../api/quadras";
import {ApiError} from "../../../api/core";
import moment from 'moment';
import sortBy from 'lodash.sortby';
import AlunosSection from "./AlunosSection";
import AulaCard from "./AulaCard";
import AulaSection from "./AulaSection";
import CreateEditMatriculaDialog from "../../../components/shared/dialogs/CreateEditMatriculaDialog";
import CreateEditTurmaDialog from "../../../components/shared/dialogs/CreateEditTurmaDialog";
import {findOne as findOneMatricula} from '../../../api/matriculas';
import {findOne as findOneQuadroAula} from "../../../api/turmas";

export default {
  name: "DisponibilidadeBoard",
  components: {AulaSection, AulaCard, AlunosSection, CreateEditMatriculaDialog, CreateEditTurmaDialog},
  props: ['searchValues'],
  filters: {
    obterQuadraPorDia(quadras, quadra, dia) {

      const q = quadras.find(qq => qq.nome === quadra && +qq.info.periodo.diaSemana === dia)
      q.info.alunos = ['a1', 'a2', 'a3', 'a4', 'a5', 'a6', 'a7', 'a8']
      return q;
    }
  },
  data() {
    return {
      values: null,
      loading: true,
      hoverKey: null,
      item: null,
			editDialogMatriculaOpened: false,
      itemQuadra: null,
      editDialogQuadroAulaOpened: false,
    }
  },
  computed: {
    refDate() {
      return this.searchValues?.refDate;
    },
    intervalDays() {
      const values = [];

      const startOfWeek = moment(this.refDate, 'YYYY-MM-DD').startOf('week');
      const endOfWeek = moment(this.refDate, 'YYYY-MM-DD').endOf('week');
      let start = startOfWeek;

      while (start.isSameOrBefore(endOfWeek)) {
        values.push({
          date: start.format('DD/MM/YYYY'),
          day: start.format('dddd'),
          weekDay: start.weekday() + 1
        });
        start.add(1, 'd');
      }

      return values;
    },
    horario() {
      return this.values && this.values.length > 0 ? this.values[0].horario : null
    },

    hasQuadras() {
      return this.quadrasKeys?.length > 0;
    },

    quadrasKeys() {
      const keys = [];
      this.quadras.forEach(quadra => {
        if (keys.indexOf(quadra.nome) === -1) {
          keys.push(quadra.nome)
        }
      });
      return sortBy(keys);
    },

    quadras() {
      const quadras = [];
      if (!this.values) return [];
      this.values.forEach(value => {
        value.tipos.forEach(tipo => {
          quadras.push(...tipo.quadras)
        })
      })
      return quadras;
    },
    quadraSize() {
      return Math.ceil(100 / this.quadrasKeys.length);
    }
  },
  watch: {
    searchValues: {
      deep: true,
      handler() {
        this.refresh();
      }
    },
    'quadrasKeys': {
      handler(v) {
        this.$emit('update:qtdQuadras', v.length)
      }
    }
  },
  methods: {
    markHover(key) {
      this.hoverKey = key;
    },
    removeKey() {
      this.hoverKey = null;
    },
    async refresh() {
      try {
        this.loading = true;
        const startOfWeek = moment(this.refDate, 'YYYY-MM-DD').startOf('week').format('DD/MM/YYYY');
        const endOfWeek = moment(this.refDate, 'YYYY-MM-DD').endOf('week').format('DD/MM/YYYY');
        const v = await findDisponibilidade({
          idHorario: this.searchValues.idHorario,
          dataIni: startOfWeek,
          dataFim: endOfWeek,
          localQuadra: this.searchValues.local
        });
        this.values = v;
        //console.log(v,this.values);
      } catch (err) {
        //console.error(err);
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível realizar a consulta.`, {
            timeout: 6000
          })
        }
      } finally {
        this.loading = false;
      }

    },
    async abreEdicaoMatricula(idMatricula){
      try {
        const page = await findOneMatricula(idMatricula);
        this.item = page.dados[0];
        this.editDialogMatriculaOpened = true;
      } catch (err) {
        this.$toast.error(`Não foi possível localizar a matrícula do aluno`, {
          timeout: 6000
        })
      }
    },
    async abreEdicaoQuadroAula(idQuadroAula){
      console.log('getIdQuadroAula Board: ' + idQuadroAula);
      try {
        const page = await findOneQuadroAula(idQuadroAula);
        this.itemQuadra = page.dados[0];
        this.editDialogQuadroAulaOpened = true;
      } catch (err) {
        this.$toast.error(`Não foi possível localizar o Quadro de Aula`, {
          timeout: 6000
        })
      }
    }
  },
}
</script>

<style scoped lang="scss">

$border-color: #dedede;
//$main-bg-color: #fafafa;
$main-bg-color: var(--v-primary-lighten1);
$min-height-day-box: 100px;
$font-main-color: var(--v-primary-base);
$font-main-color: #FFF;

.quadro {
  display: flex;
  background-color: #FFF;
  flex: 1;
  height: 100%;
  overflow: auto;
  position: relative;


  &.is-mobile {
    .calendar {
      width: 90px;

      p {
        transform: rotate(20deg);
        font-size: 0.85rem;
      }
    }
  }
}

.calendar {
  width: 150px;
}

.horario {
  border-bottom: 1px solid $border-color !important;
  border-right: 1px solid $border-color !important;
  color: $font-main-color;
  padding: 0 10px;
  text-align: center;
  background-color: $main-bg-color;
}

// teste
.dias {
  flex: 1;
  height: calc(100% - 21px);
}

.aulas {
  flex: 1;
  //height: 100%;
  height: calc(100% - 21px);
}

.day {
  min-height: $min-height-day-box;
  height: 14.28%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: $font-main-color;

  border-right: 1px solid $border-color !important;
  border-bottom: 1px solid $border-color !important;
  background-color: $main-bg-color;

}


.quadras {
  flex: 1;
  display: flex;

  //overflow-y: auto;
  max-width: calc(100vw - 406px);

  &.is-mobile {
    max-width: calc(100vw - 200px);
  }

  .quadra {
    min-width: 170px;
  }

  .quadra-info {
    border-bottom: 1px solid $border-color !important;
    border-right: 1px solid $border-color !important;
    padding: 0 10px;
    color: $font-main-color;
    text-align: center;
    background-color: $main-bg-color;
  }

  ::v-deep {
    .aula {
      min-height: $min-height-day-box;
      height: 14.28%;
      display: flex;
      flex-direction: column;
      border-right: 1px solid $border-color !important;
      border-bottom: 1px solid $border-color !important;

      .professor {
        border-bottom: 1px solid $border-color !important;
        background-color: $main-bg-color;
        color: #FFF;
      }

      p {
        padding: 0 5px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

    }

    .alunos {
      flex: 1;
      /*display: flex;*/
      flex-direction: column;
      overflow: auto;
      // height: 74px;
      transition: height 0.5s ease 0s;
    }

  }

}

::v-deep {

  .aula.hovered {
    position: relative;

    > .v-card {
      z-index: 10;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
    }

    /*
      &::before {
        content: '';
        position: fixed;
        background-color: rgba(0, 0, 0, 0.3);
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 4;  }
    */
    .alunos {
      // height: var(--qtd-alunos);
    }
  }

}

</style>