<template>
  <v-card @mouseenter="onHover" @mouseleave="onLeave"
          class="ma-2" :class="{'elevation-10': isSelected}">
    <div class="professor" :style="corFundoTurma(info.desTurmaAluguel)">
      <p class="ma-0 body-2 font-weight-bold" v-if="dadosTurma(info.desTurmaAluguel, 0)==='TU'">
        <a @click="abreQuadroAula(dadosTurma(info.desTurmaAluguel, 1))">
          <v-icon small color="white" v-show="hasTurma">mdi-tennis</v-icon>
          {{ info.nomeProfessor }}{{ info.tipoTurma !== "" && info.tipoTurma !== null ? " - " + info.tipoTurma : ""}}
        </a>
      </p>
      <p v-else class="ma-0 body-2 font-weight-bold">
        <v-icon small color="white" v-show="hasTurma">mdi-tennis</v-icon>
        {{ info.nomeProfessor }}{{ info.tipoTurma !== "" && info.tipoTurma !== null ? " - " + info.tipoTurma : ""}}
      </p>
    </div>
    <alunos-section v-if="hasTurma" 
      @sendIdMatriculaAulaCard="getIdMatricula($event)"
      @sendIdQuadroAulaAulaCard="getIdQuadroAula($event)"
      :alunos="info.alunos" :is-selected="isSelected"/>
  </v-card>
</template>

<script>
import AlunosSection from "./AlunosSection";

export default {
  name: "AulaCard",
  components: {AlunosSection},
  props: ['quadra', 'weekDay', 'info', 'selectedKey'],
  data() {
    return {
      hoverTimeout: null
    }
  },
  computed: {
    isSelected() {
      return this.valueKey === this.selectedKey;
    },
    hasTurma() {
      return this.info.desTurmaAluguel !== '';
    },
    valueKey() {
      return this.quadra + this.weekDay;
    }
  },
  methods: {
    /**
     * indice 0: tipo
     * indice 1: id
     * indice 2: nome
     */
    dadosTurma(item, indice) {
      var turma = item.split("|");
      return turma[indice];
    },
    corFundoTurma(item) {
      if(this.dadosTurma(item, 0)==='TU') return "background-color: #5E69D1"; //azul
      if(this.dadosTurma(item, 0)==='AM') return "background-color: #16A086"; //verde
      if(this.dadosTurma(item, 0)==='AA') return "background-color: #F39C11"; //laranja
      if(this.dadosTurma(item, 0)==='FA') return "background-color: #FF4D4D"; //vermelho
      return "";

      return "";
    },
    onHover() {
      clearTimeout(this.hoverTimeout);
      this.hoverTimeout = setTimeout(() => {
        this.$emit('hover', this.valueKey);
      }, 500);
    },
    onLeave() {
      clearTimeout(this.hoverTimeout);
      this.$emit('leave', null);
    },
    getIdMatricula(idMatricula){
      this.$emit('sendIdMatriculaAulaSection',idMatricula);
    },
    abreQuadroAula(idQuadroAula){
      this.$emit('sendIdQuadroAulaAulaSection',idQuadroAula);
    }
  }
}
</script>

<style scoped>

</style>