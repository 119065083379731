<template>
<v-dialog :fullscreen="$vuetify.breakpoint.mobile"
          :value="value"
          max-width="1200px"
          persistent>
  <v-card class="d-flex flex-column">
    <v-card-title>
      {{getTitle}}
      <v-spacer/>
      <v-btn icon @click="$emit('input', false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider/>
    <v-card-text class="py-4 flex-grow-1" v-if="value">

      <v-form ref="form" @submit.prevent="save" v-model="formValid" lazy-validation>
        <matricula-form v-model="item"/>
      </v-form>


    </v-card-text>
    <v-divider/>
    <v-card-actions>
      <v-spacer/>
      <v-btn outlined color="primary" @click="$emit('input', false)">Cancelar</v-btn>
      <v-btn  color="primary" @click="save" :disabled="!formValid">Salvar</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import MatriculaForm from "../forms/MatriculaForm";
import {create, update} from "../../../api/matriculas";
import TurmasDataTable from "../datatables/TurmasDataTable";
import {ApiError} from "../../../api/core";
export default {
  name: "CreateEditMatriculaDialog",
  components: {TurmasDataTable, MatriculaForm},
  props: ['value','item'],
  data () {
    return {
      formValid: false
    }
  },
  computed: {
    getTitle() {
      return this.item?.id ? 'Editar Matricula' : 'Adicionar Matricula'
    },

    cursosSelecionados() {
      return (this.item.cursos || []).filter(c => c.qtdDias != null).map(c => `${c.id}|${c.qtdDias}`).join(',')
    },
  },
  methods: {
    async save() {
      try {
        if(this.$refs.form.validate()) {
          if(this.item.id) {
            await update(this.item.id, {
              idCliente: this.item.idCliente,
              idAula: this.item.idAula,
              idLocal: this.item.idLocal,
              idCursos: this.cursosSelecionados,
              valMatricula: this.item.valMatricula,
              tipoPlano: this.item.tipoPlano,
              diaVencimento: this.item.diaVencimento,
              valDesconto: this.item.valDesconto,
              descMotivoDesconto: this.item.descMotivoDesconto,
              descNotificacoes: this.item.descNotificacoes,
              idQuadroAulas: this.item.dias.map(d => d.idQuadroAulas).join(','),
              stsMatricula: this.item.stsMatricula
            });
          } else {
            await create({
              idCliente: this.item.idCliente,
              idAula: this.item.idAula,
              idLocal: this.item.idLocal,
              idCursos: this.cursosSelecionados,
              valMatricula: this.item.valMatricula,
              tipoPlano: this.item.tipoPlano,
              diaVencimento: this.item.diaVencimento,
              valDesconto: this.item.valDesconto,
              descMotivoDesconto: this.item.descMotivoDesconto,
              descNotificacoes: this.item.descNotificacoes,              
              idQuadroAulas: this.item.dias.map(d => d.idQuadroAulas).join(','),
              stsMatricula: this.item.stsMatricula
            })
          }
          this.$emit('input', false);
          this.$emit('onSave');
          this.$toast.success(`Matricula ${this.item.id ? 'atualizada' : 'criada'} com sucesso.`, {
            timeout: 3000
          })

        }
      } catch (err) {
        if(err instanceof ApiError) {
          this.$toast.error(err.message, {
          timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível ${this.item.id ? 'atualizar' : 'adicionar'} a Matricula.`, {
            timeout: 6000
          })
        }
      }
    }
  },
}
</script>

<style scoped>

</style>