<template>
  <base-view
      @update:search-values="updateSearch"
      :search-columns="searchColumns">
    <template v-slot:actions v-if="hasQuadras">
      <v-spacer/>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-fade-transition>

          <v-btn
              outlined
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
          >
            Exportar
            <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
          </v-fade-transition>

        </template>
        <v-list>
          <v-list-item @click="exportAs('pdf')">
            <v-list-item-title>Exportar como PDF</v-list-item-title>
          </v-list-item>
          <v-divider/>
          <v-list-item @click="exportAs('csv')">
            <v-list-item-title>Exportar como CSV</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <v-fade-transition>
      <disponibilidade-board
          ref="board"
                             @update:qtdQuadras="updateQtdQuadras"
                             :search-values="searchValues"/>
    </v-fade-transition>
  </base-view>

</template>

<script>
import BaseView from "../BaseView";
import DisponibilidadeBoard from "./components/DisponibilidadeBoard";
import {ItemDeDominio} from "../../api/itemsDominio";
import {mapActions, mapState} from "vuex";
import {findAll as findAllHorario} from "../../api/horarios";
import {downloadDisponibilidadeReport, generateExportDisponibilidadeURI} from "../../api/quadras";
import moment from "moment";

export default {
  name: "index",
  components: {
    DisponibilidadeBoard, BaseView
  },
  data() {
    return {
      qtdQuadras: 0,
      searchValues: {},
      searchColumns: [
        {
          name: 'Horario',
          column: 'idHorario',
          type: 'select',
          optionsFactory: () => findAllHorario(1)
              .then(r => r.elements.map(e => ({
                key: e.id,
                valor: e.range
              })))
        },
        {
          name: 'Local',
          column: 'local',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.LOCAL_QUADRA
          })
        },
        {
          name: 'Data',
          column: 'refDate',
          type: 'date',
          defaultValue: moment().format('YYYY-MM-DD'),
          hint: 'O Sistema irá automaticamente calcular o início e o fim da Semana baseado na data Selecionada',
        },
      ]
    }
  },
  computed: {
    ...mapState('auth',['token']),
    hasQuadras() {
      return this.qtdQuadras > 0;
    },
    startOfWeek() {
      return moment(this.searchValues.refDate, 'YYYY-MM-DD').startOf('week');
    },
    endOfWeek() {
      return moment(this.searchValues.refDate, 'YYYY-MM-DD').endOf('week');
    },
  },
  methods: {
    ...mapActions('itemsDominio', ['fetch']),
    updateQtdQuadras(qtd) {
      this.qtdQuadras = qtd;
    },
    async exportAs(format) {

      await downloadDisponibilidadeReport({
        formatoSaida: format,
        idHorario: this.searchValues.idHorario,
        dataIni: this.startOfWeek.format('DD/MM/YYYY'),
        dataFim: this.endOfWeek.format('DD/MM/YYYY'),
        localQuadra: this.searchValues.local,
      })
    },
    updateSearch(search) {
      this.searchValues = search;
    },
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">

::v-deep {


    .page-pre-content.is-mobile {
      background-color: transparent !important;
    }


  .filters.opened ~ .content {
    .quadras {
      max-width: calc(100vw - 806px);
    }
  }

}

</style>