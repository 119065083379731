<template>
  <v-row>
    <!--dados da matricula-->
    <v-col cols="12" class="">
      <v-card class="px-3">
        <v-card-text class="subtitle-1 font-weight-light">
          <v-row>
            <v-col cols="12" md="6">
              <search-box
                  label="* Cliente"
                  popup-label="Selecione um Cliente"
                  :id.sync="value.idCliente"
                  :nome.sync="value.nomeIdCliente"
                  :hidden-clear="true"
                  :value="cliente"
                  :loader-fn="loaderClientes"
                  item-key="id"
                  item-text="nome"
                  :rules="[
                      this.$validators.notNullOrUndefined
                  ]">
                <template v-slot:empty>
                  <p class="ma-2">
                    Nenhum Cliente encontrado
                  </p>
                </template>
              </search-box>
            </v-col>
            <v-col cols="12" md="3">
              <search-box
                  label="* Local"
                  :hidden-clear="true"
                  popup-label="Selecione qual o local dessa quadra"
                  :id.sync="value.idLocal"
                  :descricao.sync="value.descricaoIdLocal"
                  :value="local"
                  :loader-fn="loaderLocal"
                  item-key="id"
                  item-text="descricao"
                  :rules="[
                        this.$validators.notNullOrUndefined
                    ]">
                <template v-slot:empty>
                  <p class="ma-2">
                    Nenhum local encontrado
                  </p>
                </template>
              </search-box>
            </v-col>
            <v-col cols="12" md="3">
              <lookup-dominio label="* Tipo Plano"
                              hint="Selecione qual o tipo do plano"
                              v-model="value.tipoPlano"
                              :type="lookups.tipoPlano"
                              :hidden-clear="true"
                              :rules="[
                              this.$validators.string.required
                          ]"/>
            </v-col>
            <v-col cols="12"> <!--:diaVencimento.sync="value.diaVencimento"-->
              <search-box-multiple
                  label="* Cursos"
                  class="search-box-cursos"
                  popup-label="Selecione os cursos"
                  v-model="value.cursos"
                  :loader-fn="loaderCursos"
                  item-key="id"
                  item-text="descricao"
                  :rules="[
                        this.$validators.notNullOrUndefined,
                        ruleDias
                    ]">
                <template v-slot:selection="{item}" >
                  <div @click.stop  class="curso-chip v-chip">
                    <div class="item-description">{{item.descricao}}</div>
                    <div class="dias-selection">
                    <v-select class="primary--text ma-0 pa-0" color="primary" item-color="primary"
                              v-model="item.qtdDias"
                              placeholder="Dias"
                              dense
                              hide-details style="max-width: 75px;" :items="[1,2,3,4,5,6,7,8,9,10]">
                      <template v-slot:append>{{ item.qtdDias != null ? 'Dias' : '?&nbsp;Dias' }}</template>
                    </v-select>
                    </div>
                    <div class="remove-button" @click="remove(item)">
                      <v-btn x-small icon class="ma-0 pa-0">
                        <v-icon>mdi-close-circle</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </template>
                <template v-slot:empty>
                  <p class="ma-2">
                    Nenhum Curso encontrada
                  </p>
                </template>
              </search-box-multiple>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>   
    <!--seleção de dias-->
    <v-col cols="12" md="6">
      <v-card class="px-3">
        <v-overlay :value="qtdDias == 0" absolute/>
        <v-row>
          <v-col cols="12">
            <v-icon large left >mdi-format-list-checkbox</v-icon>
            <span class="title font-weight-bold">Aulas Disponiveis</span>
          </v-col>
          <v-col cols="12" md="4" class="pt-7">
            <lookup-dominio label="Dia da Semana"
                            :hidden-refresh="true"
                            :hidden-clear="false"
                            v-model="value.diaSemana"
                            :type="lookups.diaSemana"/>
          </v-col>
          <v-col cols="12" md="4">
            <search-box
                class="mt-5"
                label="Horario"
                popup-label="Selecione um horario"
                :id.sync="value.idHorario"
                @input="updateRange"
                :value="horario"
                :loader-fn="loaderHorario"
                item-key="id"
                item-text="range"
            >
              <template v-slot:empty>
                <p class="ma-2">
                  Nenhum horario encontrado
                </p>
              </template>
            </search-box>
          </v-col>
          <v-col cols="12" md="4">
            <search-box
                class="mt-5"
                label="Tipo da Turma"
                popup-label="Selecione tipo da Turma"
                :id.sync="value.idTipoTurma"
                :descricao.sync="value.descricao_idTipoTurma"                
                :value="tipoTurma"
                :loader-fn="loaderTipoTurma"
                item-key="id"
                item-text="descricao"
            >
              <template v-slot:empty>
                <p class="ma-2">
                  Nenhum Tipo de Tuma encontrado
                </p>
              </template>

            </search-box>
          </v-col>
          <v-col cols="12">
            <turmas-data-table ref="turma" :force-hidden-header="true" :search-values="{
                                                                                        diaSemana: value.diaSemana,
                                                                                        idHorario: value.idHorario,
                                                                                        idTipoTurma: value.idTipoTurma,
                                                                                        idsNotIn: aulasSelecionadas
                                                                                      }">
              <template v-slot:body="{items}">
                <v-list class="overflow-x-auto" style="max-height: 400px">
                  <v-list-item class="aula-disponivel add" @click="add(turma)" v-for="turma of items" :key="turma.id">
                    <v-list-item-content>
                      <v-list-item-title v-text="turma.descricao_idQuadra + ' - ' + turma.descricao_idTipoTurma"/>
                      <v-list-item-subtitle v-text="turma.nome_idProfessor"/>
                    </v-list-item-content>
                    <v-list-item-action-text>
                      {{ turma.hora_inicio_idHorario }}
                      <br>
                      {{ turma.descricao_diaSemana }}
                    </v-list-item-action-text>
                  </v-list-item>
                </v-list>
              </template>
            </turmas-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <!--dias selecionados-->
    <v-col cols="12" md="6">
      <v-card class="px-3">
        <v-row>
          <v-col cols="12">
              <div class="d-flex justify-space-between">
                <span class="title font-weight-bold">
                  <v-icon large left >mdi-format-list-checks</v-icon>
                  Aulas Selecionadas
                </span>
                <v-fade-transition>
                <v-chip  v-show="qtdDias != null" input-value="false" class="white--text font-weight-bold" small :color="`${isSelectedAllAulas ? 'green' : 'red'} darken-2`">{{(value.dias || []).length}} aulas selecionadas de {{ qtdDias }} dísponiveis</v-chip>
                </v-fade-transition>
              </div>

            <v-list>
              <div v-for="turma of value.dias" :key="turma.id">
                <v-list-item class="aula-disponivel del" @click="del(turma)">
                  <v-list-item-content>
                    <v-list-item-title v-text="turma.descricao_idQuadra + ' - ' + turma.descricao_idTipoTurma"/>
                    <v-list-item-subtitle v-text="turma.nome_idProfessor"/>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    {{ turma.hora_inicio_idHorario }}
                    <br>
                    {{ turma.descDiaSemana }}
                  </v-list-item-action-text>
                </v-list-item>
              </div>
            </v-list>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <!--card cobrança de dias-->
    <v-col cols="12" class="">
      <v-card class="px-3">
        <v-card-title>
          <v-icon large left >mdi-cash-multiple</v-icon>
          <span class="title font-weight-bold">Cobrança</span>
        </v-card-title>
        <v-card-text class="subtitle-1 font-weight-light">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field dense
                            label="Valor"
                            prefix="R$"
                            :rules="[
                                this.$validators.number.required,
                                v => this.$validators.number.greaterThanOrEquals(v, 0)
                            ]"
                            v-model="value.valMatricula" type="number"/>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field dense
                            label="Dia Vencimento"
                            :rules="[
                                this.$validators.number.required,
                                v => this.$validators.number.greaterThanOrEquals(v, 1),
                                v => this.$validators.number.lessThanOrEquals(v, 28)
                            ]"
                            v-model="value.diaVencimento" type="number"/>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field dense
                            label="Desconto"
                            prefix="%"
                            @input="changeDesconto"
                            :rules="[
                                v => this.$validators.number.greaterThanOrEquals(v, 0),
                                v => this.$validators.number.lessThanOrEquals(v, 100)
                            ]"
                            v-model="value.valDesconto" type="number"/>
            </v-col>
            <v-col cols="12" sm="6" md="9">
              <v-text-field dense
                            :disabled="value.valDesconto <= 0"
                            :hide-details="value.valDesconto <= 0"
                            :label="(value.valDesconto > 0 ? '* ' : '') + 'Motivo Desconto'"
                            :rules="[
                                v => this.$validators.string.lessThanOrEquals(v, 100)
                            ]"
                            v-model="value.descMotivoDesconto"/>
            </v-col>
            <v-slide-x-transition>
              <v-col cols="12" v-if="value.desconto > 0">
                <v-textarea label="Motivo do Desconto" rows="2"/>
              </v-col>
            </v-slide-x-transition>    
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" class="">
      <v-card class="px-3">
        <v-card-title>
          <v-icon large left color="red">mdi-bell-badge-outline</v-icon>
          <span class="title font-weight-bold">Notificação</span>
        </v-card-title>
        <v-card-text class="subtitle-1 font-weight-light">
          <v-row>
            <v-col cols="12" sm="12">
            <v-textarea rows="2" label="" filled
                        :rules="[
                          v => this.$validators.string.greaterThanOrEquals(v, 0),
                          v => this.$validators.string.lessThanOrEquals(v, 1000),
                        ]" 
                        v-model="value.descNotificacoes"/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>              
  </v-row>
</template>

<script>
import SearchBox from "../common/SearchBox";
import {findAll as findAllClientes} from "../../../api/clientes";
import {findAll as findAllAulas} from "../../../api/aulas";
import {findAll as findAllCursos} from "../../../api/cursos";
import LookupDominio from "../common/LookupDominio";
import {ItemDeDominio} from "../../../api/itemsDominio";
import {findAll as findAllHorarios} from "../../../api/horarios";
import {findAll as findAllLocal} from '../../../api/locais';
import {findAll as findAllTipoTurma} from '../../../api/tipoTurma';
import TurmasDataTable from "../datatables/TurmasDataTable";
import SearchBoxOld from "../common/SearchBoxOld";
import SearchBoxMultiple from "../common/SearchBoxMultiple";

export default {
  name: "MatriculaForm",
  components: {SearchBoxMultiple, SearchBoxOld, TurmasDataTable, LookupDominio, SearchBox},
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      ruleDias(cursos) {
        const cursosSemDias = cursos.filter(curso => curso.qtdDias == null).map(curso => curso.descricao);
        return cursosSemDias.length === 0 || `Selecione a quantidade de dia para os cursos: "${cursosSemDias.join(',')}"`
      },
      lookups: {
        diaSemana: ItemDeDominio.ARR_DIAS_SEMANA,
        local: ItemDeDominio.LOCAL_QUADRA,
        tipoPlano: ItemDeDominio.TIPO_PLANO,
      }
    }
  },
  computed: {
    qtdDias() {
      return (this.value.cursos || []).reduce((v1, v2) => {
        return v1 + (v2.qtdDias || 0);
      },0)
    },
    isSelectedAllAulas() {
      return (this.value.dias || []).length === this.qtdDias;
    },
    aulasSelecionadas() {
      return (this.value.dias || []).map(d => d.idQuadroAulas)
    },
    cliente() {
      if (this.value.idCliente == null) return null;
      return {
        id: this.value.idCliente,
        nome: this.value.nomeIdCliente
      }
    },
    local() {
      if (this.value.idLocal == null) return null;
      return {
        id: this.value.idLocal,
        descricao: this.value.descricaoIdLocal
      }
    },
    horario() {
      if (this.value.idHorario == null) return null;
      return {
        id: this.value.idHorario,
        range: this.value.hora_inicio_idHorario
      }
    },
    tipoTurma() {
      if (this.value.idTipoTurma == null) return null;
      return {
        id: this.value.idTipoTurma,
        descricao: this.value.descricao_idTipoTurma
      }
    },
  },
  watch: {
    'value.valDesconto': {
      handler(newValue, oldValue) {
        if (newValue <= 0) this.value.descMotivoDesconto = "";
      }
    }
  },
  methods: {
    remove(item) {
      console.log(item);
      this.value.cursos = this.value.cursos.filter(v => v.id !== item.id);
    },
    add(value) {
      this.value.dias.push({
        idQuadroAulas: value.id,
        descricao_idQuadra: value.descricao_idQuadra,
        descricao_idTipoTurma: value.descricao_idTipoTurma,
        hora_inicio_idHorario: value.hora_inicio_idHorario,
        nome_idProfessor: value.nome_idProfessor,
        descDiaSemana: value.descricao_diaSemana,
      });
    },
    del(value) {
      this.value.dias.splice(this.value.dias.findIndex(v => v.idQuadroAulas === value.idQuadroAulas), 1);
    },
    changeDesconto() {

    },
    updateRange(range) {
      this.value.hora_inicio_idHorario = range ? range.horaInicio : null;
    },
    loaderHorario(page, search) {
      return findAllHorarios(page, {
        horaInicio: search
      })
    },
    loaderTipoTurma(page, search) {
      return findAllTipoTurma(page, {
        descricao: search
      })
    },
    loaderAula(page, search) {
      return findAllAulas(page, {
        descricao: search
      })
    },
    loaderClientes(page, search) {
      return findAllClientes(page, {
        nome: search
      })
    },
    loaderCursos(page, search) {
      return findAllCursos(page, {
        descricao: search
      })
    },
    loaderLocal(page, search) {
      return findAllLocal(page, {
        descricao: search
      })
    }
  }
}
</script>

<style scoped lang="scss">
.curso-chip {
  padding-right: 0;
  cursor: initial;
  background-color: rgba(0,0,0,0.1);
  height: 32px;
  border-radius: 16px;
  border: 1px solid #dedede;
  display: flex;
  justify-content: center;
  align-items: center;

  .item-description {
    min-width: 150px;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 5px;
  }
  .dias-selection {

    ::v-deep {

      .v-input__slot {
        height: 32px;
      }
      .v-select__slot {
        display: flex;
        flex-direction: row;
        justify-content: center;
        //height: 32px;
        //max-height: 32px;
        //padding: 4px 7px;
        padding: 0 7px;
        .v-select__selections {
          input {
            display: none;
          }
        }
        input {
          max-width: 20px;
        }
      }

      .v-input__append-inner {
        margin-top: 3px;
        align-self: center;
        font-weight: 400;
      }
      .v-select__selections {
        color: var(--v-primary-base);
        height: 32px;
        text-align: end;
        font-weight: 400;
        .select__selection {
          margin: 0 !important;
        }
      }
    }

    &:hover {
      background-color: rgba(0,0,0,0.3);
    }

    cursor: pointer;
    background-color: rgba(0,0,0,0.2);
    transition: background-color 0.5s ease 0s;
    font-weight: 500;
  }
  .remove-button {
    height: 32px;
    cursor: pointer;
    background-color: rgba(0,0,0,0.2);
    border-left: 2px solid #dedede;
    padding: 5px 8px !important;
    transition: background-color 0.5s ease 0s;
    &:hover {
      background-color: rgba(0,0,0,0.3);
    }

  }

}

.search-box-cursos ::v-deep > .v-input__control > .v-input__slot > .v-select__slot {
  > .v-select__selections {
    display: flex;
    line-height: 0;
    justify-content: flex-start;
    align-items: flex-start;
    .curso-chip {
      margin-bottom: 5px !important;
    }
  }
}

::v-deep {


  .aula-disponivel {
    position: relative;
    border-bottom: 1px solid #dedede;

    &:after {
      background-color: transparent;
      transition: background-color 0.5s ease 0s;
    }

    &:hover:after {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.3);
      position: absolute;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 1.5rem;
      padding: 0 100px;
      left: 0;
      bottom: 0;
      top: 0;
    }

    &.add:after {
      content: 'Adicionar';
      color: var(--v-primary-base);
    }

    &.del:after {
      content: 'Remover';
      color: var(--v-error-base);
    }
  }


}

</style>