var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('search-box',{attrs:{"label":"* Tipo Turma","popup-label":"Selecione um curso","id":_vm.value.idTipoTurma,"descricao":_vm.value.descricao_idTipoTurma,"value":_vm.tipoTurma,"loader-fn":_vm.loaderTipo,"item-key":"id","item-text":"descricao","rules":[
              this.$validators.notNullOrUndefined
          ]},on:{"update:id":function($event){return _vm.$set(_vm.value, "idTipoTurma", $event)},"update:descricao":function($event){return _vm.$set(_vm.value, "descricao_idTipoTurma", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhum tipo encontrado ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('search-box',{attrs:{"label":"* Quadra","popup-label":"Selecione uma quadra","id":_vm.value.idQuadra,"descricao":_vm.value.descricao_idQuadra,"value":_vm.quadra,"loader-fn":_vm.loaderQuadra,"item-key":"id","item-text":"descricao","rules":[
              this.$validators.notNullOrUndefined
          ]},on:{"update:id":function($event){return _vm.$set(_vm.value, "idQuadra", $event)},"update:descricao":function($event){return _vm.$set(_vm.value, "descricao_idQuadra", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhuma quadra encontrada ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('search-box',{attrs:{"label":"* Professor","popup-label":"Selecione um Professor","id":_vm.value.idProfessor,"nome":_vm.value.nome_idProfessor,"value":_vm.professor,"loader-fn":_vm.loaderProfessor,"item-key":"id","item-text":"nome","rules":[
              this.$validators.notNullOrUndefined
          ]},on:{"update:id":function($event){return _vm.$set(_vm.value, "idProfessor", $event)},"update:nome":function($event){return _vm.$set(_vm.value, "nome_idProfessor", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhum Professor encontrado ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('search-box',{attrs:{"label":"* Horario","popup-label":"Selecione um horario","id":_vm.value.idHorario,"value":_vm.horario,"loader-fn":_vm.loaderHorario,"item-key":"id","item-text":"range","rules":[
              this.$validators.notNullOrUndefined
          ]},on:{"update:id":function($event){return _vm.$set(_vm.value, "idHorario", $event)},"input":_vm.updateRange},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhum horario encontrado ")])]},proxy:true}])})],1),_c('v-col',{staticClass:"pt-7",attrs:{"cols":"12","md":"6"}},[_c('lookup-dominio',{attrs:{"label":"* Dia da Semana","hidden-refresh":true,"hidden-clear":true,"type":_vm.lookups.diaSemana,"rules":[
                      this.$validators.string.required
                  ]},model:{value:(_vm.value.diaSemana),callback:function ($$v) {_vm.$set(_vm.value, "diaSemana", $$v)},expression:"value.diaSemana"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }