<template>
  <div class="aula" :class="{'hovered': selectedKey === valueKey}">
    <template>
      <aula-card v-if="quadraPorDia.info.desTurmaAluguel !== ''" :info="quadraPorDia.info" :quadra="quadra"
                 :week-day="weekDay"
                 @sendIdMatriculaAulaSection="getIdMatricula($event)"
                 @sendIdQuadroAulaAulaSection="getIdQuadroAula($event)"
                 :selected-key="selectedKey" @leave="leaveTurma" @hover="hoverTurma"/>
    </template>
  </div>
</template>

<script>
import AulaCard from "./AulaCard";

export default {
  name: "AulaSection",
  components: {AulaCard},
  props: ['quadras', 'intervalDays', 'selectedKey', 'quadra', 'weekDay'],
  computed: {
    valueKey() {
      return this.quadra + this.weekDay;
    },
    quadraPorDia() {
      const q = this.quadras.find(qq => qq.nome === this.quadra && +qq.info.periodo.diaSemana === this.weekDay)
      //q.info.alunos = ['a1', 'a2', 'a3', 'a4', 'a5', 'a6', 'a7', 'a8']
      return q;
    }
  },
  methods: {
    hoverTurma(v) {
      if(this.quadraPorDia.info.alunos.length < 3) return;
      this.$emit('hoverTurma', v);
    },
    leaveTurma() {
      this.$emit('leaveTurma')
    },
    getIdMatricula(idMatricula){
      this.$emit('sendIdMatriculaBoard',idMatricula);
    },
    getIdQuadroAula(idQuadroAula){
      this.$emit('sendIdQuadroAulaBoard',idQuadroAula);
    }
  }
}
</script>

<style scoped>

</style>