<template>
  <div class="alunos"
       :style="{
                  height: height
                }">
    <v-chip class="ml-1 pa-1" :title="tituloPorTipo(aluno)" :color="corFundoAluno(aluno)" text-color="#FFFFFF" x-small
      v-for="aluno in alunos" :key="aluno">
      <span v-if="dadosAluno(aluno,0)==='MA'">
        <a @click="abreMatricula(dadosAluno(aluno,1))">{{dadosAluno(aluno,2)}}</a>
      </span>
      <span v-else>{{dadosAluno(aluno,2)}}</span>
    </v-chip>
  </div>
</template>

<script>
export default {
  name: "AlunosSection",
  props: ['isSelected', 'alunos'],
  computed: {
    hasItems() {
      return this.alunos.length > 0;
    },
    height() {
      const v = !this.isSelected ? '68px' : (Math.max(68,this.alunos.length * 26))+'px';
      return v;
    },
  },
  methods: {
    /**
     * indice 0: tipo
     * indice 1: id
     * indice 2: nome
     */
    dadosAluno(item, indice) {
      var aluno = item.split("|");
      return aluno[indice];
    },
    abreMatricula(idMatricula){
      this.$emit('sendIdMatriculaAulaCard',idMatricula);
    },
    corFundoAluno(item) {
      if(this.dadosAluno(item, 0)==='MA') return "#5E69D1"; //azul
      if(this.dadosAluno(item, 0)==='AM') return "#16A086"; //verde
      if(this.dadosAluno(item, 0)==='AA') return "#F39C11"; //laranja
      if(this.dadosAluno(item, 0)==='FA') return "#FF4D4D"; //vermelho
      return "";
    },
    tituloPorTipo(item){
      if(this.dadosAluno(item, 0)==='MA') return "Aula"; //azul
      if(this.dadosAluno(item, 0)==='AM') return "Aluguel Mensal"; //verde
      if(this.dadosAluno(item, 0)==='AA') return "Aluguel Avulso"; //laranja
      if(this.dadosAluno(item, 0)==='FA') return "Frequencia Avulsa"; //vermelho
      return "";      
    }
  }
}
</script>

<style scoped lang="scss">
.matriula {
  color: #C2C6F8;
}
.aluguelMensal{
  color: bisque;
}
.aluguelAvulso {
  color: cadetblue;
}
</style>